import bg from '../assets/bg.png';
import G from '../assets/G.png';
import knife from '../assets/knife.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';


import gericht from '../assets/gericht.png';
import homeimg from '../assets/homeimg.png';
import cofeebean from '../assets/cofeebean.png';
import kaffbonar from '../assets/kaffbonar.gif';
import kaffbonar2 from '../assets/kaffbonar2.gif';
import file from '../assets/file.png';
import india from '../assets/india.png';
import trucks from '../assets/trucks.png';
import load from '../assets/load.jpg';
import coffee from '../assets/coffee.jpg'
import flower from '../assets/flower.jpg';
import Monsoon from '../assets/Monsoon.jpeg';
import Mysore from '../assets/Mysore.jpeg';
import Washedrobusta from '../assets/Washedrobusta.jpeg';
import araku from '../assets/araku.jpeg';
import ghar from '../assets/ghar.jpeg';
import bull from '../assets/bull.jpeg';
import byson from '../assets/byson.jpeg';

import wave from '../assets/wave.gif';
import indiaimg from '../assets/indiaimg.jpg';

import watermark from '../assets/watermark.jpeg';

export default {

ghar,
wave,
bull,
byson,
indiaimg,
watermark,

  araku,
  Mysore,
  Washedrobusta,
  Monsoon,
  bg,
  flower,
  coffee,
  file,
  load,
  trucks,
  india,
  kaffbonar2,
  kaffbonar,
  cofeebean,
  homeimg,
 
  G,
  
  knife,
  
  overlaybg,
  spoon,

  

  gericht,
};
